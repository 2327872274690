import * as React from "react";
import Link from "./../../components/Link";
import Seo from "./../../components/Seo";
import "./styles.scss";

const NotFoundPage = () => (
  <>
    <Seo title="Hm..." />
    <section id="page-404" className="section is-medium">
      <h3 id="message-404">Well that didn't work.</h3>
      <h4 id="message-404-subtitle">
        Shall we go back <Link to="/">home</Link>?
      </h4>
    </section>
  </>
);

export default NotFoundPage;
